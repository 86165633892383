function downloadFile(filename, content) {
  const element = document.createElement('a');
  const file = new Blob([content], { type: 'text/plain' });

  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default downloadFile;
