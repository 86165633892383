<template>
  <el-form-item>
    <template #label>
      <el-tooltip :content="tooltip" placement="left" effect="light">
        <i class="el-icon-question"></i>
      </el-tooltip>
      <span>&nbsp;{{ label }}</span>
    </template>
    <slot></slot>
  </el-form-item>
</template>
<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
  },
};
</script>
<style></style>
